<template>
  <div class="inquiry">
    <div class="flex">
      <div class="element">
        <h2 class="label">{{ $t("overall.from") }}</h2>
        <div class="input-wrapper">
          <Input type="text" placeholder="100 000" v-model="filters.price.from" />
          <span class="unit">{{ $store.getters.getCurrency }}</span>
        </div>
      </div>
      <div class="element">
        <h2 class="label">{{ $t("overall.to") }}</h2>
        <div class="input-wrapper">
          <Input type="text" placeholder="100 000" v-model="filters.price.to" />
          <span class="unit">{{ $store.getters.getCurrency }}</span>
        </div>
      </div>

      <div class="element">
        <h2>{{ $t("properties.status.title") }}</h2>
        <Select
          v-model="filters.status"
          :options="[
            { name: $t('clients.buy'), value: 'sell' },
            { name: $t('clients.rent'), value: 'rent' },
          ]"
        />
      </div>

      <div class="box">
        <h2>{{ $t("properties.garden") }}</h2>
        <Select
          :placeholder="$t('properties.garden')"
          v-model="filters.garden"
          :options="[
            { name: $t('overall.not_specified'), value: null },
            { name: $t('overall.private'), value: 'private' },
            { name: $t('overall.community'), value: 'community' },
            { name: $t('overall.no'), value: 0, unique: true },
          ]"
        />
      </div>

      <div class="box">
        <h2>{{ $t("properties.sea_sight") }}</h2>
        <Select
          v-model="filters.sea_sight"
          :options="[
            { name: $t('overall.not_specified'), value: null },
            { name: $t('properties.first_line'), value: 'first_line' },
            { name: $t('overall.yes'), value: 1 },
            { name: $t('overall.no'), value: 0 },
          ]"
        />
      </div>

      <div class="box">
        <h2>{{ $t("properties.terrace") }}</h2>
        <Select
          v-model="filters.terrace"
          :options="[
            { name: $t('overall.not_specified'), value: null },
            { name: $t('overall.yes'), value: 1 },
            { name: $t('overall.no'), value: 0 },
          ]"
        />
      </div>

      <div class="element">
        <h2>{{ $t("properties.type.title") }}</h2>
        <Select
          v-model="filters.type"
          :options="[
            { name: $t('overall.not_specified'), value: null },
            { name: $t('properties.type.flat'), value: 'flat' },
            { name: $t('properties.type.villa'), value: 'villa' },
            { name: $t('properties.type.house'), value: 'house' },
            { name: $t('properties.type.penthouse'), value: 'penthouse' },
            { name: $t('properties.type.office'), value: 'office' },
            { name: $t('properties.type.land'), value: 'field' },
            { name: $t('properties.type.bungalow'), value: 'bungalow' },
            { name: $t('properties.type.duplex'), value: 'duplex' },
            { name: $t('properties.type.garage'), value: 'garage' },
            { name: $t('properties.type.building'), value: 'building' },
            { name: $t('properties.type.professional_office'), value: 'business_local' },
            { name: $t('properties.type.industrial_land'), value: 'industrial_land' },
            { name: $t('properties.type.agricultural_land'), value: 'agricultural_land' },
          ]"
        />
      </div>
      <div style="width: 50%">
        <h2>{{ $t("properties.indoor_area") }}</h2>
        <RangePicker v-model="filters.indoor_area" :min="0" :max="500" :step="50" :inputs="true" />
      </div>
      <div class="deal-filters" style="width: 50%">
        <div class="flex">
          <div style="width: 50%">
            <h2>{{ $t("properties.bedrooms") }}</h2>
            <RangePicker v-model="filters.bedrooms" :min="0" :max="8" :step="1" :inputs="true" />
          </div>
          <div style="width: 50%">
            <h2>{{ $t("properties.bathrooms") }}</h2>
            <RangePicker v-model="filters.bathrooms" :min="0" :max="8" :step="1" :inputs="true" />
          </div>
        </div>
      </div>
    </div>
    <div class="cta" v-on:click="$emit('changeTab')">{{ $t("clients.find_properties") }}</div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "DealInquiry",
  props: ["filters"],
  data() {
    return {};
  },
  methods: {},
  created() {},
});
</script>
