var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inquiry"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"element"},[_c('h2',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("overall.from")))]),_c('div',{staticClass:"input-wrapper"},[_c('Input',{attrs:{"type":"text","placeholder":"100 000"},model:{value:(_vm.filters.price.from),callback:function ($$v) {_vm.$set(_vm.filters.price, "from", $$v)},expression:"filters.price.from"}}),_c('span',{staticClass:"unit"},[_vm._v(_vm._s(_vm.$store.getters.getCurrency))])],1)]),_c('div',{staticClass:"element"},[_c('h2',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("overall.to")))]),_c('div',{staticClass:"input-wrapper"},[_c('Input',{attrs:{"type":"text","placeholder":"100 000"},model:{value:(_vm.filters.price.to),callback:function ($$v) {_vm.$set(_vm.filters.price, "to", $$v)},expression:"filters.price.to"}}),_c('span',{staticClass:"unit"},[_vm._v(_vm._s(_vm.$store.getters.getCurrency))])],1)]),_c('div',{staticClass:"element"},[_c('h2',[_vm._v(_vm._s(_vm.$t("properties.status.title")))]),_c('Select',{attrs:{"options":[
          { name: _vm.$t('clients.buy'), value: 'sell' },
          { name: _vm.$t('clients.rent'), value: 'rent' } ]},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c('div',{staticClass:"box"},[_c('h2',[_vm._v(_vm._s(_vm.$t("properties.garden")))]),_c('Select',{attrs:{"placeholder":_vm.$t('properties.garden'),"options":[
          { name: _vm.$t('overall.not_specified'), value: null },
          { name: _vm.$t('overall.private'), value: 'private' },
          { name: _vm.$t('overall.community'), value: 'community' },
          { name: _vm.$t('overall.no'), value: 0, unique: true } ]},model:{value:(_vm.filters.garden),callback:function ($$v) {_vm.$set(_vm.filters, "garden", $$v)},expression:"filters.garden"}})],1),_c('div',{staticClass:"box"},[_c('h2',[_vm._v(_vm._s(_vm.$t("properties.sea_sight")))]),_c('Select',{attrs:{"options":[
          { name: _vm.$t('overall.not_specified'), value: null },
          { name: _vm.$t('properties.first_line'), value: 'first_line' },
          { name: _vm.$t('overall.yes'), value: 1 },
          { name: _vm.$t('overall.no'), value: 0 } ]},model:{value:(_vm.filters.sea_sight),callback:function ($$v) {_vm.$set(_vm.filters, "sea_sight", $$v)},expression:"filters.sea_sight"}})],1),_c('div',{staticClass:"box"},[_c('h2',[_vm._v(_vm._s(_vm.$t("properties.terrace")))]),_c('Select',{attrs:{"options":[
          { name: _vm.$t('overall.not_specified'), value: null },
          { name: _vm.$t('overall.yes'), value: 1 },
          { name: _vm.$t('overall.no'), value: 0 } ]},model:{value:(_vm.filters.terrace),callback:function ($$v) {_vm.$set(_vm.filters, "terrace", $$v)},expression:"filters.terrace"}})],1),_c('div',{staticClass:"element"},[_c('h2',[_vm._v(_vm._s(_vm.$t("properties.type.title")))]),_c('Select',{attrs:{"options":[
          { name: _vm.$t('overall.not_specified'), value: null },
          { name: _vm.$t('properties.type.flat'), value: 'flat' },
          { name: _vm.$t('properties.type.villa'), value: 'villa' },
          { name: _vm.$t('properties.type.house'), value: 'house' },
          { name: _vm.$t('properties.type.penthouse'), value: 'penthouse' },
          { name: _vm.$t('properties.type.office'), value: 'office' },
          { name: _vm.$t('properties.type.land'), value: 'field' },
          { name: _vm.$t('properties.type.bungalow'), value: 'bungalow' },
          { name: _vm.$t('properties.type.duplex'), value: 'duplex' },
          { name: _vm.$t('properties.type.garage'), value: 'garage' },
          { name: _vm.$t('properties.type.building'), value: 'building' },
          { name: _vm.$t('properties.type.professional_office'), value: 'business_local' },
          { name: _vm.$t('properties.type.industrial_land'), value: 'industrial_land' },
          { name: _vm.$t('properties.type.agricultural_land'), value: 'agricultural_land' } ]},model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}})],1),_c('div',{staticStyle:{"width":"50%"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("properties.indoor_area")))]),_c('RangePicker',{attrs:{"min":0,"max":500,"step":50,"inputs":true},model:{value:(_vm.filters.indoor_area),callback:function ($$v) {_vm.$set(_vm.filters, "indoor_area", $$v)},expression:"filters.indoor_area"}})],1),_c('div',{staticClass:"deal-filters",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"flex"},[_c('div',{staticStyle:{"width":"50%"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("properties.bedrooms")))]),_c('RangePicker',{attrs:{"min":0,"max":8,"step":1,"inputs":true},model:{value:(_vm.filters.bedrooms),callback:function ($$v) {_vm.$set(_vm.filters, "bedrooms", $$v)},expression:"filters.bedrooms"}})],1),_c('div',{staticStyle:{"width":"50%"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("properties.bathrooms")))]),_c('RangePicker',{attrs:{"min":0,"max":8,"step":1,"inputs":true},model:{value:(_vm.filters.bathrooms),callback:function ($$v) {_vm.$set(_vm.filters, "bathrooms", $$v)},expression:"filters.bathrooms"}})],1)])])]),_c('div',{staticClass:"cta",on:{"click":function($event){return _vm.$emit('changeTab')}}},[_vm._v(_vm._s(_vm.$t("clients.find_properties")))])])}
var staticRenderFns = []

export { render, staticRenderFns }